import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

import DownloadImg from "../../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../../static/assets/image/setupImg1.png"
import setupImg2 from "../../../static/assets/image/setupImg2.png"
import setupImg3 from "../../../static/assets/image/setupImg3.png"
import setupImg4 from "../../../static/assets/image/setupImg4.png"
import setupImg5 from "../../../static/assets/image/setupImg5.png"
import setupImg6 from "../../../static/assets/image/setupImg6.png"
import setupImg7 from "../../../static/assets/image/setupImg7.png"
// import setupImg8 from "../static/assets/image/setupImg8.png"
import updateImg1 from "../../../static/assets/image/setupImg1.png"
import updateImg2 from "../../../static/assets/image/setupImg2.png"
import updateImg3 from "../../../static/assets/image/setupImg3.png"
import updateImg4 from "../../../static/assets/image/setupImg4.png"
import updateImg5 from "../../../static/assets/image/setupImg5.png"

const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                  <div className="membersZoneWrapper soloPage indMember">
            <h2>COMMB Data Report</h2>
            <p className="lead">These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
            <hr />
            <br />
            <div className="videosWrapper">
              <div>
                <h3>Video 1</h3>
                <p>Understanding the basics</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Xr_IESWeLPA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>         
              <div>
                <h3>Video 2</h3>
                <p>How to build the default report for Product Groups or Networks by Market</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/lkPK38hWa48" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Video 3</h3>
                <p>How to build a major market report with CSD details broken out</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/fUDH0QweCLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Video 4</h3>
                <p>How to determine changes in inventory vs the previous wave period</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/bHgJzBTuu2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>          
              <div>
                <h3>Video 5</h3>
                <p>How to create a report without subtotals (table mode)</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vsrkF_bXbfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
              <h3>Video 6</h3>
                <p>How to group Outdoor digital and static products separately in a report</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yUDTJwEuwqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
              <h3>Video 7</h3>
                <p>How to run a report for a custom outdoor company market</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jmRi93jbTic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Video 8</h3>
                <p>How to run a Pattison market report with proximate CSDs</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sxdGny9F_O0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              </div> 
            </div>
            </Layout>
      </div>
      
    )
}

export default MembersZone